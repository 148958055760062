import React, { memo, useCallback, useState } from 'react';
import { Box, Typography } from '@mui/material';

import { IconNames } from 'ui/theme';
import { Icon } from 'ui/components/Icon';
import { EmailModal } from 'ui/components/Modal/EmailModal';
import { downloadReport, ReportId } from 'services/reports';

import { ReportsViewerContentProps } from './types';
import { useReportsViewerContentStyle } from './styled';
import { PdfViewer } from '../PdfViewer';
import { DownloadFileType } from './consts';

const ReportsViewerContent: React.FC<ReportsViewerContentProps> = (props) => {
  const {
    report,
    reportName,
    params,
    setIsLoading,
    reportTheme,
    reportVersion,
  } = props;

  const [showEmailModal, setShowEmailModal] = useState(false);
  const classes = useReportsViewerContentStyle();

  const handleDownloadClicked = useCallback(
    async (downloadFileType: DownloadFileType) => {
      setIsLoading(true);
      await downloadReport(
        reportName,
        params,
        downloadFileType,
        undefined,
        reportTheme,
        reportVersion
      );
      setIsLoading(false);
    },
    [reportName, params, setIsLoading, reportTheme, reportVersion]
  );

  const printWindowLayout =
    'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0';

  const printWindowStyles = (size: string = 'auto') => `
      @page 
      {
          size: ${size};   /* auto is the initial value */
          margin: 0mm;  /* this affects the margin in the printer settings */
      }
      @media print {
        #header,
        #footer,
        #nav {
          display: none !important;
        }
      }
    `;

  const renderHtmlReportCss = () => {
    // Locate the html-report-div
    const htmlReportDiv = document.getElementById('html-report-div');

    // Create the new printing window and document
    const printWindow = window.open('', '', printWindowLayout);

    if (printWindow && htmlReportDiv) {
      const headElem = printWindow.document.head;
      const bodyElem = printWindow.document.body;

      // Add header styles for page printing
      const headerStyle = document.createElement('style');
      headerStyle.innerText = printWindowStyles();
      headElem.appendChild(headerStyle);

      // Add report html to body
      if (htmlReportDiv) {
        const cleanReportDiv = document.createElement('div');
        cleanReportDiv.style['padding'] = '30px';
        cleanReportDiv.innerHTML = htmlReportDiv.innerHTML;
        bodyElem.appendChild(cleanReportDiv);
      }

      // Render print window/modal
      printWindow.document.close();
      printWindow.focus();

      // Display UserAgent specific print modal
      const browserMatch =
        navigator.userAgent.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || [];

      switch (browserMatch[1]) {
        case 'Safari': {
          setTimeout(() => {
            printWindow.document.execCommand('print', false, undefined);
            printWindow.close();
          }, 500);
          break;
        }
        default: {
          printWindow.print();
          printWindow.close();
        }
      }
    }
  };

  const handlePrintClicked = useCallback(async () => {
    setIsLoading(true);
    renderHtmlReportCss();
    setIsLoading(false);
  }, [reportName, params, setIsLoading, reportTheme, reportVersion]);

  const handleEmailClicked = () => {
    setShowEmailModal(true);
  };

  const handleEmailClose = () => {
    setShowEmailModal(false);
  };

  const renderEmptyViewer = () => (
    <Box className={classes.contentInner} data-qa="generate-report-prompt">
      <Box className={classes.iconContainer}>
        <Icon
          name={IconNames.PDF}
          width={42}
          height={42}
          className={classes.icon}
        />
      </Box>
      <Typography variant="body1">
        Press <b>"Generate Report"</b> to view report
      </Typography>
    </Box>
  );

  const renderViewer = () => {
    if (reportName !== '') {
      return (
        <PdfViewer
          report={report}
          reportName={reportName}
          params={params}
          onDownloadClicked={handleDownloadClicked}
          onPrintClicked={handlePrintClicked}
          onEmailClicked={handleEmailClicked}
          reportTheme={reportTheme}
          reportVersion={reportVersion}
        />
      );
    } else {
      return renderEmptyViewer();
    }
  };

  return (
    <>
      <Box className={classes.content}>{renderViewer()}</Box>
      <EmailModal
        show={showEmailModal}
        onClose={handleEmailClose}
        reportId={reportName as ReportId}
        parameters={params}
        reportTheme={reportTheme}
        reportVersion={reportVersion}
      />
    </>
  );
};

export default memo(ReportsViewerContent);
