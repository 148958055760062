import React, { memo, useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';

import { activeUserHasPermission } from 'services/user/redux';
import {
  fetchPermissionGroupsAPI,
  PermissionGroup,
} from 'services/permissions';

import { UserPermissionGroupsProps } from './types';
import { editUserPermissions } from '../helpers';

const UserPermissionGroups: React.FC<UserPermissionGroupsProps> = (props) => {
  const { user, setUser } = props;

  const [permissionGroups, setPermissionGroups] = useState<PermissionGroup[]>(
    []
  );

  const canEditUser = editUserPermissions(user);

  const canClick = useSelector(activeUserHasPermission(canEditUser));

  useEffect(() => {
    (async () => {
      try {
        const resGroups = await fetchPermissionGroupsAPI();
        setPermissionGroups(resGroups);
      } catch {
        // do nothing
      }
    })();
  }, []);

  const userHasGroup = (groupId: number) =>
    !!user.accessGroupIds.find((g) => g === groupId);

  const handleGroupChange =
    (group: PermissionGroup) =>
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (checked) {
        setUser((old) => ({
          ...old,
          accessGroupIds: [...old.accessGroupIds, group.id!],
        }));
      } else {
        setUser((old) => ({
          ...old,
          accessGroupIds: old.accessGroupIds.filter((g) => g !== group.id!),
        }));
      }
    };

  return (
    <>
      <Typography variant="subtitle1">
        <b>Permissions</b>
      </Typography>
      <Box pl={2} sx={{ marginTop: '16px' }}>
        <Grid container>
          {permissionGroups.map((group) => (
            <Grid item xs={4} key={`group_${group.id}`}>
              <FormControlLabel
                control={
                  <Checkbox
                    className="redesign large-checkbox"
                    checked={userHasGroup(group.id!)}
                    onChange={handleGroupChange(group)}
                    color="primary"
                  />
                }
                disabled={!canClick}
                label={group.name}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default memo(UserPermissionGroups);
