import { Item } from 'services/items';
import {
  AutoPurchaseOrderItem,
  createPurchaseOrder,
  getPurchaseOrderId,
  initialPurchaseOrder,
  initialPurchaseOrderItem,
  PurchaseOrder,
} from 'services/purchaseOrders';
import { Vendor } from 'services/vendors';

const findInventory = (item: Item, locationId: number) => {
  const inventory = item.inventoryLocationSummaryList.find(
    (ils) => ils.rootLocationId === locationId
  );
  return inventory || null;
};

const findReorderPoint = (item: Item, locationId: number) => {
  const reorderPoint = item.reorderPointsList.find(
    (r) => r.locationId === locationId
  );
  return reorderPoint || null;
};

export const resolveQuantity = (item: Item, locationId: number) => {
  const inventory = findInventory(item, locationId);
  const reorderPoint = findReorderPoint(item, locationId);
  const quantityToOrder =
    (reorderPoint?.max ?? 0) -
    (inventory?.totalQty ?? 0) +
    (inventory?.allocatedOnPurchaseOrders ?? 0) +
    (inventory?.allocatedOnSalesOrders ?? 0) -
    (inventory?.qtyIncomingOnPurchaseOrders ?? 0) -
    (inventory?.qtyIncomingOnSalesOrders ?? 0);
  return quantityToOrder > 0 ? quantityToOrder : 0;
};

const applyVendorMin = (autoPoItem: AutoPurchaseOrderItem) => {
  return (
    autoPoItem.vendorMinimumDetail?.hasVendorMinimum &&
    autoPoItem.vendorMinimumDetail?.vendorId === autoPoItem.vendorId
  );
};

export const filterReorderPointsAndLocations = (
  autoItems: AutoPurchaseOrderItem[],
  locationId: number
) => {
  return autoItems.map((ai) => ({
    ...ai,
    item: {
      ...ai.item,
      reorderPointsList: ai.item.reorderPointsList.filter(
        (r) => r.locationId === locationId
      ),
      inventoryLocationSummaryList: ai.item.inventoryLocationSummaryList.filter(
        (ils) => ils.rootLocationId === locationId
      ),
      defaultUomId: !applyVendorMin(ai)
        ? ai.item.defaultUomId
        : ai.vendorMinimumDetail?.uomId ?? null,
      cost: !applyVendorMin(ai)
        ? ai.item.cost
        : ai.vendorMinimumDetail?.cost ?? 0,
    },
    quantity: applyVendorMin(ai)
      ? ai.vendorMinimumDetail?.quantity ?? 0
      : resolveQuantity(ai.item, locationId),
  }));
};

export const createAutoPurchaseOrders = async (
  autoItems: AutoPurchaseOrderItem[],
  locationId: number,
  vendors: Vendor[]
) => {
  const newOrders: PurchaseOrder[] = [];

  for (const obj of autoItems) {
    const index = newOrders.findIndex((p) => p.vendorId === obj.vendorId);
    const vendor = vendors.find((v) => v.id === obj.vendorId) || null;
    const vendorItem =
      obj.item?.vendorItems?.find((vi) => vi.vendorId === obj.vendorId) || null;
    if (index === -1) {
      const nextPoNumber = await getPurchaseOrderId();
      newOrders.push({
        ...initialPurchaseOrder,
        locationId: locationId,
        vendorId: obj.vendorId,
        exchangeCurrencyId: vendor?.currencyId ? vendor.currencyId : 1,
        vendor: vendor,
        number: nextPoNumber.toString(),
        purchaseOrderItemList: [
          {
            ...initialPurchaseOrderItem,
            item: obj.item,
            itemId: obj.item.id,
            unitCost: vendorItem?.cost ?? 0,
            quantity: obj.quantity,
            uomId: obj.item.defaultUomId,
          },
        ],
      });
    } else {
      newOrders[index].purchaseOrderItemList.push({
        ...initialPurchaseOrderItem,
        item: obj.item,
        itemId: obj.item.id,
        unitCost: vendorItem?.cost ?? 0,
        quantity: obj.quantity,
        uomId: obj.item.defaultUomId,
      });
    }
  }

  // set line numbers in po items
  const orderedPurchaseOrders = newOrders.map((o) => ({
    ...o,
    purchaseOrderItemList: o.purchaseOrderItemList.map((item, index) => ({
      ...item,
      lineNumber: index,
    })),
  }));

  return orderedPurchaseOrders;
};

export const createPurchaseOrders = async (purchaseOrders: PurchaseOrder[]) => {
  for (const po of purchaseOrders) {
    await createPurchaseOrder(po);
  }
};

export const createPOsAndReturnIds = async (
  purchaseOrders: PurchaseOrder[]
) => {
  const createdPOs = await Promise.all(
    purchaseOrders.map(
      async (purchaseOrder: PurchaseOrder) =>
        await createPurchaseOrder(purchaseOrder)
    )
  );

  return createdPOs.map((po) => po.id);
};
