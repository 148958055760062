import { ColumnTypes } from 'ui/components/Table/ItemsTable';
import { Column } from 'ui/components/Table/ItemsTable/types';

import { GlobalUser, User, UserV2 } from './types';

export const USERS_COLUMNS: Column[] = [
  {
    title: 'FIRST NAME',
    field: 'firstName',
    type: ColumnTypes.string,
  },
  {
    title: 'LAST NAME',
    field: 'lastName',
    type: ColumnTypes.string,
  },
  {
    title: 'EMAIL',
    field: 'email',
    type: ColumnTypes.string,
  },
  {
    title: 'PHONE NUMBER',
    field: 'phoneNumber',
    type: ColumnTypes.string,
  },
  {
    title: 'PHONE TYPE',
    field: 'phoneType',
    type: ColumnTypes.string,
  },
  {
    title: 'Timezone',
    field: 'timezone',
    type: ColumnTypes.string,
  },
  {
    title: 'DEFAULT LOCATION',
    field: 'defaultLocation.displayName',
    type: ColumnTypes.string,
  },
];

export const GLOBAL_USERS_COLUMNS: Column[] = [
  {
    title: 'FIRST NAME',
    field: 'nameFirst',
    type: ColumnTypes.string,
  },
  {
    title: 'LAST NAME',
    field: 'nameLast',
    type: ColumnTypes.string,
  },
  {
    title: 'EMAIL',
    field: 'email',
    type: ColumnTypes.string,
  },
];

export const initialUser: User = {
  id: null,
  admin: false,
  dateCreated: null,
  dateLastModified: null,
  defaultContact: false,
  defaultLocation: null,
  defaultLocationId: null,
  deleted: false,
  email: null,
  phoneNumber: null,
  phoneType: null,
  firstName: null,
  lastName: null,
  permissionGroups: [],
  registered: null,
  timezone: null,
  useEmail: false,
  version: null,
  tenant: null,
  name: null,
  tenantAccountType: null,
};

export const initialUserV2: UserV2 = {
  id: null,
  admin: false,
  dateCreated: null,
  dateLastModified: null,
  defaultContact: false,
  defaultLocationId: null,
  deleted: false,
  email: null,
  phoneNumber: null,
  phoneType: null,
  firstName: null,
  lastName: null,
  registered: null,
  timezone: null,
  useEmail: false,
  version: null,
  tenant: null,
  createdById: null,
  lastModifiedById: null,
  accessGroupIds: [],
  locationIdsPermitted: [],
  initials: null,
  dateDeleted: null,
  permissions: [],
};

export const initialGlobalUser: GlobalUser = {
  id: null,
  nameFirst: null,
  nameLast: null,
  email: null,
  companyId: null,
  company: null,
  tenant: null,
  name: null,
  tenantAccountType: null,
  dateLoginAttempt: null,
};
