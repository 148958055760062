import { Dates } from 'helpers';
import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

export const alwaysOnColumns: Column[] = [
  {
    title: 'First Name',
    field: 'firstName',
    sortable: true,
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Last Name',
    field: 'lastName',
    sortable: true,
    type: ColumnTypes.renderByCustomRow,
  },
];
export const extraColumn = (date: Dates): Column[] => {
  const resolveDateColumn = (): Column => {
    if (date === Dates.DateLastModified) {
      return {
        title: 'Date Last Modified',
        field: Dates.DateLastModified,
        sortable: true,
        type: ColumnTypes.renderByCustomRow,
        searchable: false,
      };
    }
    return {
      title: 'Date Created',
      field: Dates.DateCreated,
      sortable: true,
      type: ColumnTypes.renderByCustomRow,
      searchable: false,
    };
  };

  return [
    {
      title: 'Timezone',
      field: 'timezone',
      sortable: true,
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: 'Default Location',
      field: 'defaultLocation.displayName',
      type: ColumnTypes.renderByCustomRow,
      sortable: true,
    },
    resolveDateColumn(),
  ];
};
